<template>
	<v-container class="course-settings-checkbox">
		<v-row>
			<v-col cols="6">
				<input-select
					v-model="template"
					:id="String(template)"
					:items="checkboxes"
					:item-text="'name'"
					:item-value="'id'"
				>
				</input-select>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "CourseSettingsCheckbox",
	props: ["checkboxes", "selectedTemplateId"],
	computed: {
		template: {
			get() {
				return this.selectedTemplateId;
			},
			set(val) {
				this.$emit("setValue", {
					key: "selectedTemplateId",
					value: val
				});
			}
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	}
};
</script>

<style></style>
