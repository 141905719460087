<template>
	<div class="container">

		<div class="row">
			<div class="col-10">
				<v-form ref="form" class="brand-form">
					<InputImageFile
						v-model="organizationLogo"
						:id="'organizationLogo'"
						:name="'organizationLogo'"
					>
						<template #span>ORGANIZATION LOGO</template>
						<template #button>Upload New Logo</template>
					</InputImageFile>
					<InputImageFile
						v-model="organizationSignature"
						:id="'organization_signature'"
						:name="'organization_signature'"
					>
						<template #span>SIGNATURE</template>
						<template #button>Upload New Logo</template>
					</InputImageFile>

					<InputTextField
						:id="'courseName'"
						v-model="courseName"
						:rules="[rules.required]"
					>
						<template #label>Course Name</template>
					</InputTextField>
					<InputTextArea v-model="description" :id="'description'">
						<template #label> Description</template>
					</InputTextArea>
				</v-form>
			</div>
		</div>

		<div class="col-10">
				<div class="row justify-content-center align-items-center">
					<div class="col text-right">
						<v-btn
							class="admin-primary-button primary-contrast-background shadow-none"
							:loading="isLoading"
							@click="save"
							>Done</v-btn
						>
					</div>
				</div>
			</div>

		</div>
	<!-- <v-container class="main-container">
		<AdminDefaultSubheader class="mb-5"
			>Select Diploma Template</AdminDefaultSubheader
		>
		<CourseSettingsCheckbox
			:checkboxes="templates"
			:selectedTemplateId="selectedTemplateId"
			@setValue="setValue"
		></CourseSettingsCheckbox>
		<AdminDefaultSubheader class="mb-5 mt-15"
			>Customize Diploma</AdminDefaultSubheader
		>
		<v-row>
			<v-col cols="7">
				<InputImageFile
					v-model="organizationLogo"
					:id="'organizationLogo'"
					:name="'organizationLogo'"
				>
					<template #span>Organization Logo</template>
					<template #button>Upload New Logo</template>
				</InputImageFile>
				<InputImageFile
					v-model="organizationSignature"
					:id="'organization_signature'"
					:name="'organization_signature'"
				>
					<template #span>Signature</template>
					<template #button>Upload New Logo</template>
				</InputImageFile>

				<InputTextField
					:id="'courseName'"
					v-model="courseName"
					:rules="[rules.required]"
				>
					<template #label>Course Name</template>
				</InputTextField>
				<InputTextArea v-model="description" :id="'description'">
					<template #label> Description</template>
				</InputTextArea>
			</v-col>
		</v-row>
		<form-message v-if="message" :type="type">{{ message }}</form-message>
		<v-row>
			<v-col>
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="saveOrgTemplate"
					>Save</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import CourseSettingsCheckbox from "../../admin/course-settings/CourseSettingsCheckbox.vue";
import { get } from "@/util/requests/get";
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";
import FormMessage from "@/components/FormMessage.vue";
import InputImageFile from "@/components/InputImageFile.vue";

export default {
	name: "CourseSettingsDiploma",
	components: {
		AdminDefaultSubheader,
		CourseSettingsCheckbox,
		FormMessage,
		InputImageFile
	},
	props: ["courseId", "course"],
	computed: {
		...mapGetters(["organization"])
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			organizationLogo: null,
			organizationSignature: null,
			courseName: null,
			description: null,
			templates: null,
			selectedTemplateId: null,
			message: "",
			type: ""
		};
	},
	methods: {
		setValue({ key, value }) {
			this[key] = value;
		},
		async getTemplates() {
			const {
				data: { data }
			} = await get("/cert_tpls");
			this.templates = data;
		},
		async saveOrgTemplate() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const {
					data: { data }
				} = await post("/org_cert_tpl", {
					cert_tpl_id: this.selectedTemplateId,
					organization_id: this.organization.id,
					course_id: this.courseId,
					quiz_id: this.course.quizzes[0].quiz_id,
					course_name: this.courseName,
					course_description: this.description,
					passing_percent: "70",
					comment: ""
				});
				if (this.organizationLogo) {
					await post(
						"/org_cert_tpl_logo",
						{
							org_cert_tpl_id: data.id,
							file: this.organizationLogo,
							caption: "test"
						},
						{
							headers: {
								"Content-Type": "multipart/form-data"
							}
						}
					);
				}
				if (this.organizationSignature) {
					await post(
						"/org_cert_tpl_sig",
						{
							org_cert_tpl_id: data.id,
							file: this.organizationSignature,
							caption: "test"
						},
						{
							headers: {
								"Content-Type": "multipart/form-data"
							}
						}
					);
				}
				this.message = "Success";
				this.message = "success";
				await this.$store.dispatch("setIsLoading", false);
			} catch (error) {
				this.message = error;
				console.log(error);
				await this.$store.dispatch("setIsLoading", false);
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	},
	async created() {
		await this.getTemplates();
	}
};
</script>

<style lang="scss">
#CourseSettingsDiploma {
	.image-file-input {
		max-width: 100% !important;
	}
}
</style>
